import {
  CartForm,
  CollectionGrid,
  DividerPhoto,
  Grid,
  GridSize,
  GridColumn,
  Hero,
  ProductHero,
  ReviewWidget,
  Video,
  Marquee,
  ShopSort,
  ShopFilter,
  BlogPostGrid,
  BlogPostHeader,
  BlogPostBody,
  BlogPostReadMore,
  BlogCategoryPostGrid,
  BlogAuthorPostGrid,
  PricingPlans,
  SanityColorCode,
  TeamMembersGrid,
  TechStack,
  PredefinedPageModule,
} from '@data/sanity/schema'
import { Keyed } from '@lib/helpers'
import { SanityBlocks } from './blocks'
import {
  SanityBlogAuthor,
  SanityBlogCategory,
  SanityBlogPostOptions,
  SanityBlogPostWithoutBody,
} from './blog'
import { SanityContentFragment, SanityLink } from './content'
import { SanityImageFragment } from './image'
import { SanityHasSlug } from './page'
import { SanityProductFragment } from './product'
import { HasCollectionStrings, HasReviewsStrings } from './site'
import { SanityMuxVideo, SanityVideo, SanityVimeoVideoMedia } from './video'

export enum SanityModuleType {
  GRID = 'grid',
  HERO = 'hero',
  DIVIDER_PHOTO = 'dividerPhoto',
  PRODUCT_HERO = 'productHero',
  COLLECTION_GRID = 'collectionGrid',
  REVIEW_WIDGET = 'reviewWidget',
  CART_FORM = 'cartForm',
  VIDEO = 'video',
  MARQUEE = 'marquee',
  BLOG_POST_GRID = 'blogPostGrid',
  BLOG_POST_HEADER = 'blogPostHeader',
  BLOG_POST_BODY = 'blogPostBody',
  BLOG_POST_READ_MORE = 'blogPostReadMore',
  BLOG_CATEGORY_POST_GRID = 'blogCategoryPostGrid',
  BLOG_AUTHOR_POST_GRID = 'blogAuthorPostGrid',
  PRICING_PLANS = 'pricingPlans',
  TEAM_MEMBERS_GRID = 'teamMembersGrid',
  TECH_STACK = 'techStack',
  PREDEFINED_PAGE_MODULE = 'predefinedPageModule',
}

// Module component types
export enum SanityColor {
  WHITE = 'white',
  BLACK = 'black',
}

export type SanityGridColumnSize = Pick<
  GridSize,
  'breakpoint' | 'width' | 'justify' | 'align' | 'start'
>

export type SanityGridColumn = Keyed<
  Pick<GridColumn, 'spacing'> & {
    sizes: Array<SanityGridColumnSize>
    blocks: Array<SanityBlocks>
  }
>

export type SanityGrid = Pick<
  Grid,
  'size' | 'noColumnGaps' | 'noRowGaps' | 'reverseSequence' | 'spacing'
> & {
  background?: SanityColor
  columns: Array<SanityGridColumn>
}

export interface SanityHeroPhotos {
  mobilePhoto?: SanityImageFragment
  desktopPhoto?: SanityImageFragment
}

export type SanityHeroVimeoVideo = Pick<SanityVimeoVideoMedia, 'link' | 'name'>

export enum SanityHeroBackgroundType {
  PHOTO = 'photo',
  VIMEO_VIDEO = 'video',
  MUX_VIDEO = 'mux-video',
}

export enum SanityHeroContentPosition {
  CENTER = 'center',
  BOTTOM_LEFT = 'bottom-left',
}

export interface SanityHero {
  bgType: SanityHeroBackgroundType
  contentPosition: SanityHeroContentPosition
  content?: Array<SanityContentFragment>
  photos?: SanityHeroPhotos
  vimeoVideo?: SanityHeroVimeoVideo
  muxVideo?: SanityMuxVideo
}

export interface SanityDividerPhoto {
  photo: SanityImageFragment
}

export enum SanityFilterType {
  SIMPLE = ' ',
  SWATCH = 'swatch',
}

export enum SanityFilterGroupDisplay {
  DEFAULT = ' ',
  GRID = 'grid',
}

export type SanityFilterGroupOption = SanityHasSlug & {
  type: SanityFilterType
  title: string
  color?: SanityColorCode
}

export type SanityFilterGroup = Keyed<
  SanityHasSlug & {
    display: SanityFilterGroupDisplay
    title: string
    options: Array<SanityFilterGroupOption>
  }
>

export type SanityFilter = Pick<ShopFilter, 'isActive'> & {
  groups?: Array<SanityFilterGroup>
}

export enum SanitySortOptionType {
  FEATURED = 'featured',
  ALPHA_ASCENDING = 'alphaAsc',
  ALPHA_DESCENDING = 'alphaDesc',
  DATE_ASCENDING = 'dateAsc',
  DATE_DESCENDING = 'dateDesc',
  PRICE_ASCENDING = 'priceAsc',
  PRICE_DESCENDING = 'priceDesc',
}

export type SanitySortOption = {
  type: SanitySortOptionType
  title: string
}

export type SanitySort = Pick<ShopSort, 'isActive'> & {
  options?: Array<SanitySortOption>
}

export type SanityCollectionGrid = Pick<CollectionGrid, 'active'> & {
  paginationLimit: number
  title?: string
  filter?: SanityFilter
  sort?: SanitySort
}

export enum SanityReviewWidgetType {
  MAIN = 'main',
  PRODUCT = 'product',
}

export type SanityReviewWidget = {
  type: SanityReviewWidgetType
}

export type SanityCartForm = Pick<CartForm, 'active'>

export type SanityMarqueeSimpleItem = {
  _type: 'simple'
  text: string
}

export type SanityMarqueePhotoItem = {
  _type: 'photo'
  photo: SanityImageFragment
}

export type SanityMarqueeProductItem = {
  _type: 'product'
  _id: string
  product: SanityProductFragment
}

export type SanityMarqueeItem = Keyed<
  SanityMarqueeSimpleItem | SanityMarqueePhotoItem | SanityMarqueeProductItem
>

export type SanityMarquee = Pick<
  Marquee,
  'speed' | 'maxItemWidth' | 'reverse' | 'pausable'
> & {
  items: Array<SanityMarqueeItem>
  content?: Array<SanityContentFragment>
  contentAlignment?: 'left' | 'center' | 'right'
}

export interface SanityBlogPostGrid {
  posts: Array<SanityBlogPostWithoutBody>
  author?: SanityBlogAuthor
  category?: SanityBlogCategory
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostHeader {
  post?: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostBody {
  content: SanityContentFragment
}

export interface SanityBlogPostReadMore {
  posts: Array<SanityBlogPostWithoutBody>
}

export type SanityBlogCategoryPostGrid = Pick<SanityBlogPostGrid, 'options'> & {
  posts: Array<SanityBlogPostWithoutBody>
}

export type SanityBlogAuthorPostGrid = Pick<SanityBlogPostGrid, 'options'> & {
  posts: Array<SanityBlogPostWithoutBody>
}

export interface SanityPricingPlan {
  name?: string
  price?: string
  description?: string
  featured?: boolean
  features?: string
  button?: { label?: string } & SanityLink
}

export interface SanityPricingPlans {
  plans: Array<SanityPricingPlan>
}

export interface SanityTeamMembersGrid {
  title?: string
  subtitle?: string
  teamMembers?: Array<{
    name: string
    slug: string
    jobTitle?: string
    about?: string
    linkedInUrl?: string
    blogAuthor?: {
      slug: string
    }
    photo?: SanityImageFragment
  }>
}

export type SanityTechStackItem = SanityImageFragment

type SanityTechStackRow = Keyed<{
  heading: string
  subheading?: string
  items: SanityTechStackItem[]
}>

export interface SanityTechStack {
  heading?: string
  content?: SanityContentFragment
  rows: SanityTechStackRow[]
}

// Module raw data types
export type SanityRawModule =
  | Grid
  | Hero
  | DividerPhoto
  | ProductHero
  | CollectionGrid
  | ReviewWidget
  | CartForm
  | Video
  | Marquee
  | BlogPostGrid
  | BlogPostHeader
  | BlogPostBody
  | BlogPostReadMore
  | PricingPlans

// Module data types
export type SanityGridModule = Keyed<Pick<Grid, '_type'> & SanityGrid>

export type SanityHeroModule = Keyed<Pick<Hero, '_type'> & SanityHero>

export type SanityDividerPhotoModule = Keyed<
  Pick<DividerPhoto, '_type'> & SanityDividerPhoto
>

export type SanityProductHeroModule = Keyed<Pick<ProductHero, '_type'>>

export type SanityCollectionGridModule = Keyed<
  Pick<CollectionGrid, '_type'> & SanityCollectionGrid & HasCollectionStrings
>

export type SanityReviewWidgetModule = Keyed<
  Pick<ReviewWidget, '_type'> & SanityReviewWidget & HasReviewsStrings
>

export type SanityCartFormModule = Keyed<
  Pick<CartForm, '_type'> & SanityCartForm
>

export type SanityVideoModule = Keyed<Pick<Video, '_type'> & SanityVideo>

export type SanityMarqueeModule = Keyed<Pick<Marquee, '_type'> & SanityMarquee>

export type SanityBlogPostGridModule = Keyed<
  Pick<BlogPostGrid, '_type'> & SanityBlogPostGrid
>

export type SanityBlogPostHeaderModule = Keyed<
  Pick<BlogPostHeader, '_type'> & SanityBlogPostHeader
>

export type SanityBlogPostBodyModule = Keyed<
  Pick<BlogPostBody, '_type'> & SanityBlogPostBody
>

export type SanityBlogPostReadMoreModule = Keyed<
  Pick<BlogPostReadMore, '_type'> & SanityBlogPostReadMore
>

export type SanityBlogCategoryPostGridModule = Keyed<
  Pick<BlogCategoryPostGrid, '_type'> & SanityBlogCategoryPostGrid
>

export type SanityBlogAuthorPostGridModule = Keyed<
  Pick<BlogAuthorPostGrid, '_type'> & SanityBlogAuthorPostGrid
>

export type SanityPricingPlansModule = Keyed<
  Pick<PricingPlans, '_type'> & SanityPricingPlans
>

export type SanityTeamMembersGridModule = Keyed<
  Pick<TeamMembersGrid, '_type'> & SanityTeamMembersGrid
>

export type SanityTechStackModule = Keyed<
  Pick<TechStack, '_type'> & SanityTechStack
>

type SanityBaseModule =
  | SanityGridModule
  | SanityHeroModule
  | SanityDividerPhotoModule
  | SanityProductHeroModule
  | SanityCollectionGridModule
  | SanityReviewWidgetModule
  | SanityCartFormModule
  | SanityVideoModule
  | SanityMarqueeModule
  | SanityBlogPostGridModule
  | SanityBlogPostHeaderModule
  | SanityBlogPostBodyModule
  | SanityBlogPostReadMoreModule
  | SanityBlogCategoryPostGridModule
  | SanityBlogAuthorPostGridModule
  | SanityPricingPlansModule
  | SanityTeamMembersGridModule
  | SanityTechStackModule

interface SanityPredefinedPageModule {
  pageModules: SanityBaseModule[]
}

export type SanityPredefinedPageModuleModule = Keyed<
  Pick<PredefinedPageModule, '_type'> & SanityPredefinedPageModule
>

export type SanityModule = SanityBaseModule | SanityPredefinedPageModuleModule
